/* Reset Password Form Container */
.reset-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  /* Input Fields */
  .reset-password-container input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Reset Password Button */
  .reset-password-container button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
  
  /* Reset Password Button Hover */
  .reset-password-container button:hover {
    background-color: #45a049;
  }

  /* Toast Container
.Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    margin: 1rem;
    max-width: 400px;
  }
  
  /* Toast */
  /* .Toastify__toast {
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    padding: 1rem;
    margin-top: 0.5rem;
  } */
  
  /* Success Toast */
  /* .Toastify__toast--success {
    background-color: #4caf50;
  } */
  
  /* Error Toast */
  /* .Toastify__toast--error {
    background-color: #f44336;
  } */ 

  /* Reset Password Button */
.reset-password-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
  
  /* Reset Password Button Hover */
  .reset-password-button:hover {
    background-color: #45a049;
  }
  
  /* Toggle Password Button */
  .toggle-password-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #ccc;
    color: #333;
    cursor: pointer;
  }
  
  /* Toggle Password Button Hover */
  .toggle-password-button:hover {
    background-color: #ddd;
  }
  
  