/* Privacy.css */

.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff; /* Background color */
    border: 1px solid #eb445a; /* Border color */
    border-radius: 8px; /* Border radius for rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift */
}

.privacy-heading {
    color: #eb445a; /* Company color for headings */
    font-size: 24px; /* Increase font size for headings */
    margin-bottom: 20px; /* Add some space below headings */
}

.privacy-intro,
.privacy-section,
.privacy-paragraph,
.privacy-list {
    color: #333; /* Text color */
    margin-bottom: 15px;
}

.privacy-list li {
    list-style-type: disc;
    margin-left: 20px; /* Add some indentation for list items */
}

.privacy-section {
    border-bottom: 2px solid #eb445a; /* Underline sections with company color */
    padding-bottom: 10px; /* Add some space below section titles */
}

.privacy-contact-email {
    color: #eb445a; /* Company color for contact email link */
    text-decoration: none;
}

.thank-you {
    margin-top: 20px;
    font-style: italic;
    color: #888;
}

.terms-card {
    margin-bottom: 20px;
}

.terms-card-title {
    color: #eb445a;
    font-size: 18px;
    margin-bottom: 10px;
}

.terms-card-content {
    color: #333;
    margin-left: 20px;
}

.delete-account-section {
    margin-top: 30px;
}

.delete-account-link {
    color: #eb445a;
    text-decoration: underline;
}