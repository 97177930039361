/* Deletion.css */

.deletion-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.deletion-heading {
    color: #eb445a;
    text-align: center;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
    width: 100%;
}

input, textarea {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

textarea {
    height: 100px;
    resize: vertical;
}

.checkbox-label {
    display: flex;
    align-items: flex-start !important; /* Apply !important */
    margin-bottom: 15px;
    text-align: left;
    width: 100%;
}

.checkbox-label input {
    margin-right: 10px;
}

button {
    background-color: #eb445a;
    color: white;
    padding: 12px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

button:hover {
    background-color: #d63031;
}
