/* Home.css */

.home-container {
    position: relative; /* This is needed as a context for absolute positioning */
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.promo-image {
    top: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    width: 350px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional, for rounded corners */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional, for a subtle shadow */
}


.home-heading {
    color: #eb445a;
    font-size: 36px;
    margin-bottom: 20px;
}

.home-description {
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
}

.advantages-heading {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.advantages-list {
    list-style-type: decimal;
    padding-left: 20px;
    text-align: left; /* Align the advantages list to the left */
}

.advantages-list li {
    margin-bottom: 15px;
    font-size: 16px;
    color: #444;
}

.how-it-works-heading {
    color: #333;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.how-it-works-list {
    list-style-type: decimal;
    padding-left: 20px;
    text-align: left; /* Align the how-it-works list to the left */
}

.how-it-works-list li {
    margin-bottom: 15px;
    font-size: 16px;
    color: #444;
}

.home-footer {
    margin-top: 30px;
    font-style: italic;
    color: #888;
    font-size: 14px;
}

.download-section {
    margin-top: 40px;
    padding: 20px;
}

.download-button {
    display: inline-block;
    background-color: #eb445a; /* Google Play's brand color */
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    text-decoration: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: #c23344; /* Darker shade for hover effect */
}

